<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style='text-align:right;'>
        <el-button type="primary" @click='$router.go(-1)'>返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content bgFFF paddingLR20 paddingT20">
      <div class="padding20">
        <h2 class="title tabSty paddingLR20">
          <div class="title_icon">
          </div>
          基本信息
        </h2>
        <infoTable :num='4' :tableData="infoData" :dataValue="dataValue"></infoTable>
        <h2 class="title tabSty paddingLR20 marginT20">
          <div class="title_icon">
          </div>
          车辆信息
        </h2>
        <div>
            <el-row>
                <el-col :span="8">
                    <div style="padding: 0 10px;line-height: 100%">
                    <div class='pic-title'>入场时序图</div>
                    <img class="car-pic" :src="dataValue.fullPicURL" alt="" @click="showBigImg(dataValue.fullPicURL)">
                    </div>
                </el-col>
                <el-col :span="8">
                    <div style="padding: 0 10px;">
                        <div class='pic-title'>车辆特写</div>
                    <img class="car-pic" :src="dataValue.featPicURL" alt="" @click="showBigImg(dataValue.featPicURL)">
                    </div>
                </el-col>
                <el-col :span="8">
                    <div style="padding: 0 10px;">
                        <div class='pic-title'>车牌特写</div>
                    <img class="car-pic" :src="dataValue.platePicURL" alt="" @click="showBigImg(dataValue.platePicURL)">
                    </div>
                </el-col>
            </el-row>
        </div>
      </div>
    </div>


    <!--<div class="buttonHandle">-->
      <!--<el-button type="primary" icon="el-icon-search" @click="lookParkRecordList()" >取消</el-button>-->
    <!--</div>-->
  </div>
</template>
<script>
   import infoTable from '@/components/infoTable/infoTable'
     import {
    dateFormat, showBigImage
  } from '@/common/js/public.js'
  export default {
    name: 'hello',
    data () {
      return {
          dataValue: {},
          infoData: [
              {
                name: '报警编号',
                key: 'alarmCode' // 带有key 的 内容 需要分发
            }, {
                name: '报警类型',
                key: 'type',
                format(val) {
                    switch (val) {
                        case 1:
                            return '车牌遮挡';
                        default:
                            return '车牌遮挡';
                    }
                }
            }, {
                name: '入场时间',
                key: 'reportTime',
                format(val) {
                    return dateFormat(new Date(val * 1))
                }
            }, {
                name: '车场名称',
                key: 'parkName'
            }, {
                name: '车场类型',
                key: 'parkType',
                format(val) {
                    switch (val * 1) {
                        case 1:
                            return '路侧平行';
                        case 2:
                            return '路侧垂停';
                        case 3:
                            return '封闭车场';
                        case 4:
                            return '半封闭车场';
                        default:
                            return '无';
                    }
                }
            }, {
                name: '泊位号',
                key: 'berthCode'
            }, {
                name: '地址',
                key: 'parkAddress'
            }, {
                name: '',
                key: ''
            }]
      }
    },
    methods: {
      // 放大图片
      showBigImg (url) {
        showBigImage(url);
      },
      getDetail () {
        let url = '/acb/2.0/plateCover/detail/' + this.$route.query.alarmId
          this.$axios.get(url).then(res => {
          if (res.state === 0) {
            // console.log('this.$route.query', this.$route.query);
            this.dataValue = {...res.value, ...this.$route.query};
          }
        })
      }
    },
    components: {
      infoTable
//      history
    },
    created () {
      // this.getDetail()
    },
    mounted () {
      if (this.$route.query.alarmId) {
        this.query = this.$route.query;
      }
      this.getDetail();
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="stylus" rel="stylesheet/stylus">
  .el-table .success-row {
    background: #f0f9eb !important;
  }
  .car-pic{
      width:100%;
      height: 250px;
  }
  .tableWrapper{
    /*width:800px;*/
    line-height: 44px;
    overflow: hidden;
  }
  .name{
    background:rgba(249,250,252,1);
    color:#475669;
  }
  .value{
    padding-left:18px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .name,.value{
    border:1px solid #E0E6ED;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .carImg{
    float left;
    display: inline-block;
    width: 28%;
    height: 270px;
    border: 1px solid #d9d9d9;
  }
  .carInfo{
    float right;
    display: inline-block;
    width: 70%;
    margin-left: 10px;
  }
  .imageLong{
    width: 99.5%;
    height: 260px;
    border: 1px solid #d9d9d9;
  }
  .buttonHandle{
    text-align: center;
  }
  .Line{
    position: relative;
  }
  .lineSize{
    display: inline-block;
    position: relative;
    z-index: 2;
    font-size: 20px;
    color: #CCCCCC;
    background-color: #fff;
    margin: 20px;
    padding: 0 10px;
  }
  .pic-title{
      line-height: 30px;
  }
  .showLine{
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    bottom: 25px;
    background-color: #e4e7ed;
    /*background-color: rebeccapurple;*/
    z-index: 1;
  }
  .tabSty{
    height: 42px;
    line-height 42px;
    background-color: #e0e6ed;
    width: 100%;
    margin-bottom 10px;
  }
  .title_icon{
    margin-top 13px !important;
  }
  .car_img
    background-size: 100% 100%;
    height 260px;
  /*height 200px*/
    /*cursor pointer*/
</style>
